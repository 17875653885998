import React from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { FaMapMarkerAlt } from "react-icons/fa";

const containerStyle = {
  width: "100%",
  height: "400px",
};

function ContactMap({ lng, lat }) {
  const center = {
    lat: lat,
    lng: lng,
  };
  return (
    <LoadScript googleMapsApiKey="AIzaSyAvkb3MLrcSEtRvmaGQRf5BkF3m9jU10os">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={13}
        aria-label="Map"
      >
        {/* Child components, such as markers, info windows, etc. */}
        <FaMapMarkerAlt />
        <></>
      </GoogleMap>
    </LoadScript>
  );
}

export default React.memo(ContactMap);
