/** @jsx jsx */
import { Box, jsx } from "theme-ui";
import { darken } from "@theme-ui/color";
import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import ContactMap from "../components/ContactMap";
import {
  FaEnvelope,
  FaAt,
  FaPhoneAlt,
  FaTwitter,
  FaFacebookF,
  FaYoutube,
  FaInstagram,
} from "react-icons/fa";
import Obfuscate from "react-obfuscate";
import SEO from "../components/Seo";

// markup
const ContactPage = ({ data }) => {
  const bannerImage = getImage(data.graphCmsPagesContact.featuredImage);

  return (
    <Layout>
      <SEO title="Contact" />
      <GatsbyImage image={bannerImage} alt="" />
      <Box sx={styles}>
        <Box className="textBlock">
          <h1>{data.graphCmsPagesContact.title}</h1>
          <div
            className="bodyText"
            dangerouslySetInnerHTML={{
              __html: data.graphCmsPagesContact.bodyText.html,
            }}
          />
        </Box>

        <Box className="contactRow">
          <Box>
            <Box className="contactDetail">
              <FaEnvelope alt="Postal Address" />
              <div
                className="bodyText"
                dangerouslySetInnerHTML={{
                  __html: data.graphCmsPagesContact.contactAddress.html,
                }}
              />
            </Box>

            <Box className="contactDetail">
              <FaAt alt="Email" />
              <p>
                <Obfuscate email={data.graphCmsPagesContact.contactEmail} />
              </p>
            </Box>
            <Box className="contactDetail">
              <FaPhoneAlt alt="Telepone" />
              <p>
                <Obfuscate tel={data.graphCmsPagesContact.contactTelephone} />
              </p>
            </Box>

            <p
              sx={{ display: "flex", justifyContent: ["center", "flex-start"] }}
            >
              Follow us on Social Media
            </p>

            <Box className="socialLinks">
              <a href={data.graphCmsPagesContact.twitter}>
                <FaTwitter alt="twitter" />
              </a>
              <a href={data.graphCmsPagesContact.facebook}>
                <FaFacebookF alt="facebook" />
              </a>
              <a href={data.graphCmsPagesContact.youtube}>
                <FaYoutube alt="youtube" />
              </a>
              <a href={data.graphCmsPagesContact.instagram}>
                <FaInstagram alt="instagram" />
              </a>
            </Box>
          </Box>

          <Box>
            <ContactMap
              lng={data.graphCmsPagesContact.map.longitude}
              lat={data.graphCmsPagesContact.map.latitude}
            />
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default ContactPage;

export const query = graphql`
  query ContactPageQuery {
    graphCmsPagesContact(
      id: { eq: "PagesContact:cksj3wi4g15jy0b64l2adf03h:PUBLISHED" }
    ) {
      id
      title
      contactAddress {
        raw
        html
      }
      contactTelephone
      contactEmail
      twitter
      facebook
      instagram
      youtube
      featuredImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: DOMINANT_COLOR
          aspectRatio: 1.97
        )
        alt
      }
      map {
        latitude
        longitude
      }
      bodyText {
        raw
        html
      }
    }
  }
`;

const styles = {
  "& .textBlock": {
    mx: [3, 4],
    textAlign: "center",
    fontSize: 3,
    mb: 4,
  },
  "& .contactRow": {
    display: ["block", "flex"],
    mx: [3, 4],
    mb: 4,
    "& > div": {
      width: ["100%", "50%"],
    },
    "& .contactDetail": {
      mb: 3,
      display: "flex",
      justifyContent: ["center", "flex-start"],
      p: {
        mt: 0,
        ml: 3,
      },
      svg: {
        fontSize: 4,
        color: "house2",
      },
      a: {
        color: "black",
        p: 1,
        "&:hover, &:focus-within": {
          color: "white",
          bg: "house2",
          textDecoration: "none",
        },
      },
    },
  },
  "& .socialLinks": {
    display: "flex",
    justifyContent: ["center", "flex-start"],
    mb: 4,
    svg: {
      fontSize: 4,
      mr: 3,
      color: "house2",
    },
    "& a:hover": {
      color: darken("house2", 0.1),
    },
  },
};
